import axios from 'axios';
import { useJobId } from '../contexts/ContextJbo';
import { useContext } from "react";
import { CurrencyContext } from "../contexts/CurrencyContext";
import { UserContext } from '../contexts/UserContext';
import { ThemeContext } from '../contexts/ThemeContext';
import { BASE_URL } from './config';
import * as Sentry from "@sentry/react";

/**
 * Custom hook to create an Axios instance with specific configurations and interceptors.
 * It leverages contexts for job ID and currency to enrich each request with additional data.
 *
 * @returns {AxiosInstance} - The configured Axios instance.
 */
export function useAxios() {
  // Accessing job ID and currency from their respective contexts.
  const { jboId } = useJobId();
  const theme = useContext(ThemeContext);
  const { userId } = useContext(UserContext);
  const { currentCurrency } = useContext(CurrencyContext);

  // Creating an Axios instance with a predefined base URL and headers.
  const instance = axios.create({
    baseURL: BASE_URL,
    headers: {
      'Content-Type': 'application/json',
    },
    timeout: 60000, // Timeout in milliseconds (60s)
  });

  // Interceptor to modify every outgoing request.
  instance.interceptors.request.use((config) => {
    console.log("payload data", config.data);
    // Adding job ID and currency information to the request's payload.
    config.data = {
      data: JSON.stringify({
        ...config.data,
        client_id: jboId,
        jbo_id: jboId,
        user_id: userId,
        currency: currentCurrency,
      }),
    };
    return config;
  }, (error) => {
    return Promise.reject(error);
  });

  // Retry logic when a response returns a 502 error, retry twice
  instance.interceptors.response.use(response => {
    // If the response is successful, return it
    return response;
  }, async (error) => {
    const config = error.config;

    // Initialize retry count if not already set
    config.__retryCount = config.__retryCount || 0;

    // Check if it's a 502 error and if we haven't retried more than twice
    if (error.response && error.response.status === 502 && config.__retryCount < 2) {
      config.__retryCount += 1;  // Increment the retry count

      try {
        // Retry the request with the same payload
        return await new Promise((resolve) => {
          setTimeout(() => {
            resolve(instance(config));
          }, 1000); // Add a delay before retrying (1 second)
        });
      } catch (retryError) {
        return Promise.reject(retryError);
      }
    }

    // If retries are exhausted or it's another type of error, reject it
    return Promise.reject(error);
  });

  return instance;
}
